import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { Footer } from "./components/footer";
import { Player } from "./pages/player";

import "./style/defaults/variables.css"
import "./style/defaults/page-setup.css"
import "./style/defaults/transitions.css"

export default function App() {
  return <>
    <BrowserRouter>
      <ScrollToTop />

      <Routes>
        <Route path="/" element={<Navigate to="/play/radio-mix" />} />
        <Route path="sr" element={<Navigate to="/play/radio-mix" />} />

        <Route path="play">
          <Route path="radio-mix" element={<Player
            station="Radio Mix"
            audioUrl="https://stream-140.zeno.fm/qmhf2yd9dm0uv?zs=o_HfV9beS26q5kacXeBaeA"
            apiUrl="https://apiv2.simulatorradio.com/metadata/combined"
            apiHistoryUrl="https://apiv2.simulatorradio.com/metadata/history?limit="
            apiTimetableUrl="https://apiv2.simulatorradio.com/timetable?day="
          />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <Footer />
  </>
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


export const stations = [
  {
    station: "Radio Mix",
    url: "/play/radio-mix"
  },
]
